import React, { useState } from 'react';
import { makeStyles, useTheme  } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import axiosInstance from '../../config/axiosConfig';
import CustomerForm from './CustomerForm';
import EnqueueForm from './EnqueueForm';
import CreateOrVerifyForm from './CreateOrVerifyForm';

export default function FormDialog({open, setOpen, refresh}) {
  const [cellPhone, setCellPhone] = useState('');
  const [people, setPeople] = useState('');
  const [comments, setComments] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [vaccination, setVaccination] = useState(false)
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [userOK, setUserOK] = React.useState(false);
  const [noSuchUser, setNoSuchUser] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleChange = (event, setter) => {
    setter(event.target.value);
  }

  // const handleCommentsChange = (event) => {
  //   setComments(event.target.value);
  // }

  // const handleCellChange = (event) => {
  //   setCellPhone(event.target.value);
  // };

  // const handleNameChange = (event) => {
  //   setFirstName(event.target.value);
  // };

  // const handlePeopleChange = (event) => {
  //     setPeople(event.target.value);
  // }

  const handleClear = () => {
    switch(activeStep){
      case 1:
        setNoSuchUser(false);
        setFirstName('');
        setLastName('');
        setEmail('');
        setPeople('');
        setComments('');
        break;

      case 2:
        setPeople('');
        setComments('');
        setVaccination(false)
        break;
    }
    
  }

  const handleClose = () => {
    setNoSuchUser(false);
    setActiveStep(0);
    setPeople('');
    setCellPhone('');
    setComments('');
    setFirstName('');
    setOpen(false);
    setHelperText("");
    setError(false);
  };

  const handleCustomerSearch = (e) => {
    if(!e || e.key === 'Enter'){
      getCustomerByCellPhone(cellPhone);
    }
  }

  const getCustomerByCellPhone = async (cellPhone) => {
    await axiosInstance.get('/getCustomerByCellPhone', { params: {cellPhone: cellPhone } })
    .then(res => {
      const data = res.data[0];
      if(data.error)
      {
        if(data.error === 'No such customer'){
          setNoSuchUser(true)
          setActiveStep(prev=>prev+1)
        }
        else {
          setError(true);
          setHelperText(data.error);
        }
      }
      else
      {
        setError(false);
        setHelperText('')
        setNoSuchUser(false);
        setFirstName(data.FirstName);
        setLastName(data.LastName || '');
        setEmail(data.Email || '');
        setActiveStep(prev=>prev+1)
      }
  })
    .catch(err => {
      setError(true);
      setHelperText('Something went wrong, check your internet connection or try again');
    })
  }

  const addUserToQ = async (cellPhone, people, comments, vaccination) => {
    setHelperText("");
    setError(false);
    await axiosInstance.post('/addusertoq', {
      cellPhone: cellPhone,
      people: parseInt(people),
      queueID: 1,
      comments: comments,
      vaccinated: vaccination
    })
    .then(res => {
      handleClose();
      refresh();
      console.log(res.data)
  })
    .catch(err => {
      /* Hofix, needs to be properly fixed sending if user is created or exists */
      console.log('Error', err.response.data)
      if(err.response.data.message === 'Σφάλμα μετάδοσης ειδοποίησης'){
        handleClose();
        refresh();
      }
      else{
        setError(true);
        setHelperText(err.response.data.message);
      }
      
    })
  }

  const addCustomer = async (firstName, cellPhone, lastName, email) => {
    setHelperText("");
    setError(false);
    await axiosInstance.post('/register', {
      cellPhone: cellPhone,
      firstName: firstName,
      lastName: lastName,
      email: email,
      deviceToken: 'NotAvailable'
    })
    .then(res => {
      if(res.data[0].error)
      {
        setError(true);
        setHelperText(res.data[0].error);
      }
      else
      {
        setActiveStep(prev=>prev+1)
        // addUserToQ(cellPhone, people, comments)
        // handleClose();
        // refresh();
        console.log(res.data)
      }
  })
    .catch(err => {setError(true); setHelperText('')})
  }

  const handleSubmit = () => {
    if(userOK)
      addUserToQ(cellPhone, people, comments, vaccination)
    if(noSuchUser)
      addCustomer(firstName, cellPhone);
  }

  return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Βάλε χρήστη στην ουρά</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Enqueue user manually
          </DialogContentText> */}
          <Grid container alignItems="center">

            <Grid item xs={12}>
              <Stepper alternativeLabel activeStep={activeStep}>
                <Step key={1}>
                  <StepLabel>
                    Αναζήτηση κινητού πελάτη
                  </StepLabel>
                </Step>
                <Step key={2}>
                  <StepLabel>
                   {noSuchUser ? 'Δημιουργία πελάτη' : 'Επιβεβαίωση πελάτη'}
                  </StepLabel>
                </Step>
                <Step key={3}>
                  <StepLabel>
                    Enqueue customer
                  </StepLabel>
                </Step>
              </Stepper>
            </Grid>
          
            <Grid item container justify="center" xs={12}>
              {{
                0: <CustomerForm 
                      error={error}
                      helperText={helperText}
                      cellPhone={cellPhone}
                      setCellPhone={setCellPhone}
                      firstName={firstName}
                      setFirstName={setFirstName}
                      handleChange={handleChange}
                      handleCustomerSearch={handleCustomerSearch}
                      getCustomerByCellPhone={getCustomerByCellPhone}
                      addCustomer={addCustomer}
                      setActiveStep={setActiveStep}
                    />,
                
                1: <CreateOrVerifyForm
                      error={error}
                      helperText={helperText}
                      cellPhone={cellPhone}
                      setCellPhone={setCellPhone}
                      firstName={firstName}
                      setFirstName={setFirstName}
                      lastName={lastName}
                      setLastName={setLastName}
                      email={email}
                      setEmail={setEmail}
                      handleChange={handleChange}
                      noSuchUser={noSuchUser}
                      addCustomer={addCustomer}
                    />,

                  2: <EnqueueForm 
                        error={error}
                        helperText={helperText}
                        people={people}
                        setPeople={setPeople}
                        comments={comments}
                        setComments={setComments}
                        vaccination={vaccination}
                        setVaccination={setVaccination}
                        handleChange={handleChange}
                      />
                }[activeStep]
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ακύρωση
          </Button>
          
            {{
              0 : <></>,

              1:  <React.Fragment>
                    <Button onClick={()=> {handleClear();setActiveStep(prev=>prev-1)}}>
                      Πίσω
                    </Button>
                  {noSuchUser ? null : 
                    <Button onClick={() => setActiveStep(prev => prev+1)}>
                      Επιβεβαίωση
                    </Button>
                  }
                  </React.Fragment>,
              2:  <React.Fragment>
                    <Button onClick={()=> {handleClear(); setActiveStep(prev=>prev-1)}}>
                      Πίσω
                    </Button> 
                    <Button onClick={() => addUserToQ(cellPhone, people, comments, vaccination)}>
                      Queue me
                    </Button>
                  </React.Fragment>,
             }[activeStep]
            }
          
        </DialogActions>
      </Dialog>
  );
}