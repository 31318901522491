import axiosConfig from '../config/axiosConfig'
import { toast } from "react-toastify";

//import auth from './authService';

// axiosConfig.interceptors.response.use(null, (error) => {
//   const expectedError =
//     error.response &&
//     error.response.status >= 400 &&
//     error.respone.status < 500;

//   if (!expectedError) {
//     console.log("logging the error", error);
//     alert("An unexpected error occurred:" + error);
//   }
//   return Promise.reject(error);
// });

export function setToken(token) {
  axiosConfig.defaults.token = token;
}

export function setUserId(userId) {
  axiosConfig.defaults.userId = userId;
}

export function setSessionGuid(sessionGuid) {
  axiosConfig.defaults.sessionGuid = sessionGuid;
}

// export async function getData(dataKey, parameters, apiData = {}) {
//   return await callApi(process.env.REACT_APP_BASEURL + config.apiEndPointGetData, dataKey, parameters, apiData)
//   .then(res => res)
//   .catch(err => {
//     console.error(err);
//     throw err
//   })
// }

// async function callApi(apiEndPoint, dataKey, parameters, apiData = {}) {
//   try {
//     const body = {
//       sessionGuid: Axios.defaults.sessionGuid,
//       userId: Axios.defaults.userId, //auth.getUserId(),
//       dataKey: dataKey,
//       parameters: JSON.stringify(parameters),
//         data: JSON.stringify(apiData),
//       status: null,
//       token: Axios.defaults.token, // auth.getJwt() //,
//     };

//     const headers = {
//       post: { "Content-Type": "application/json" },
//     };

//     const response = await Axios.post(apiEndPoint, body, headers);
//     const { data } = response;
//     if (data.status === config.apiStatusOK || data.status === config.apiStatusNoChanges) {
//       data.data = JSON.parse(data.data);
//       data.parameters = JSON.parse(data.parameters);
//     }
//     return data;
//   } catch (err) {
//     console.log('User printed error:\n', err.response)
//     if(!err.response){
//       throw {
//           message:'Server is not responding',
//           response: null
//         };
//     }
//     switch(err.response.status){
//       case 401:
//         throw {
//             message:'Authorization failed',
//             response: err.response
//           };
      
//       default:
//         throw {
//           message:'An error has occured',
//           response: err.response
//         };;
//     }
//   } finally {
//   }
// }

const http = {
  get: axiosConfig.get,
  post: axiosConfig.post,
  put: axiosConfig.put,
  delete: axiosConfig.delete,
  setToken,
  setUserId,
  setSessionGuid,
};

export default http;