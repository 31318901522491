import { createMuiTheme } from '@material-ui/core/styles';

const darkTheme = createMuiTheme({
    typography: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 16,
    },
    
    palette: {
        type: 'dark',
        background:{
            // default: '#232323', //'#121212',
            // paper: '#424242'//'#383838',
            root: '#121212'
        },
    }
})

export default darkTheme