import axios from 'axios';

export default axios.create({
    baseURL: 'https://qmsrv.qubiteq.gr/Auth',
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});
