import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles(() => ({
    textField : {
        margin:2
    }
}))

const CustomerForm = ({error, helperText,cellPhone, setCellPhone, setActiveStep, handleChange, handleCustomerSearch, getCustomerByCellPhone, addCustomer}) => {
    const classes = useStyles();

    return (
        <Grid item container xs={12} direction="row" justify="center" alignItems="center">
            <Grid item xs={6}>
                <TextField
                    error={error}
                    helperText={helperText}
                    fullWidth
                    autoFocus
                    required
                    margin="dense"
                    id="cellphone"
                    label="Cell Phone"
                    value={cellPhone}
                    onChange={(e) => handleChange(e,setCellPhone)}
                    onKeyPress={handleCustomerSearch}
                    className={classes.textField}
                />
            </Grid>
            {/* <TextField
                error={error}
                helperText={helperText}
                required={false}
                autoFocus
                margin="dense"
                id="firstName"
                label="First Name"
                value={firstName}
                onChange={(e) => handleChange(e,setFirstName)}
                className={classes.textField}
            /> */}
            <Grid item xs={1}>
                <IconButton onClick={() => getCustomerByCellPhone(cellPhone)}>
                    <SearchIcon />
                </IconButton>
            </Grid>

            {/* <IconButton onClick={() => addCustomer(firstName, cellPhone)}>
                <Tooltip title="Create customer">
                    <CreateIcon color='primary'/>
                </Tooltip>
            </IconButton> */}
        </Grid>
    )
    
}

export default CustomerForm;