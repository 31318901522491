import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import QueueControl from './QueueControl';
import ProtectedRoute from './Common/ProtectedRoute';
import Login from './Login/Login'
import PrivacyPolicy from './PrivacyPolicy';

const Router = ({lightTheme, setLightTheme}) => {
    return (
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Login} />
            <ProtectedRoute path="/ControlPanel" lightTheme={lightTheme} setLightTheme={setLightTheme} component={QueueControl} />
            <Route path="/login" component={Login} />
            <Route path="/privacypolicy" component={PrivacyPolicy}/>
            <Redirect to="/"/>
          </Switch>
        </BrowserRouter>
    )
}

export default Router;