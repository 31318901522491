
// const primaryColor = "#9c27b0";
const containerFluid = {
    // paddingRight: "15px",
    // paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start',
    alignContent:'flex-start',
    
    // maxHeight: '3vh'
  };
  const container = {
    ...containerFluid,
    "@media (min-width: 576px)": {
      maxWidth: "540px"
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px"
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px"
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px"
    }
  };

const footerStyle = {
  
  footContainer: {
    display:'flex',
    width:'100%',

    alignContent:'center',
    // maxHeight:'auto'
  },

  footer: {
    // backgroundColor: '#d2d8b3',
    textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative",
    flexGrow:1,
  },

  clock: {
    alignSelf: 'center',
    color: "inherit",
    paddingLeft: "0.8rem",
    fontWeight: "600",
    fontSize: "0.8rem",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    // position: "relative",
    display: "flex",
  },

  container,

  // list: {
  //   marginBottom: "0",
  //   padding: "0",
  //   marginTop: "0",
  //   display:'flex',
  //   flexDirection: 'row',
  //   alignContent: 'center',
  //   flexWrap: 'nowrap',
    
  //   width:'100%'
  // },

  spacer: {
    flexGrow:1,
    
  },

  inlineBlock: {
    width: "auto",
    
  },

  block: {
    color: "inherit",
    // padding: "0.9375rem",
    fontWeight: "300",
    fontSize: "0.9=8rem",
    textTransform: "uppercase",
    // borderRadius: "1px",
    textDecoration: "none",
    position: "relative",
    display: "flex",
    whiteSpace: 'nowrap'
  },
  
  a: {
    textDecoration: "none",
    fontFamily:"'Roboto', sans-serif",
  },


};
export default footerStyle;