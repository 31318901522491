import { createMuiTheme } from '@material-ui/core/styles';

const lightTheme = createMuiTheme({
    typography: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 16,
        fontWeightLight: 50,
        fontWeightRegular: 100,
        fontWeightMedium: 200,
        // fontColor: '#ff0000'
    },
    
})

export default lightTheme