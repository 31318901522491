import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../Services/authService";

const ProtectedRoute = ({path, component: Component, render, ...rest}) => {
    console.log(rest)
    return (
        <Route
          {...rest}
          render={props => {
              if (!auth.getCurrentUser())
              return (
                  <Redirect 
                    to={{
                        pathname: '/login',
                        state: { from: props.location }
                    }}
                />
              );
              return Component ? <Component {...props} {...rest} /> : render(props);
          }}
        />
    );
};

export default ProtectedRoute;