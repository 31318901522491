import React, { useState, useEffect } from 'react';
import axiosInstance from '../config/axiosConfig'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SendIcon from '@material-ui/icons/Send';
import { IconButton } from '@material-ui/core';
import {toast} from 'react-toastify';

export default function MessageDialog({open, setOpen, userToSend, messages, refresh}) {
  // const [messages, setMessages] = useState([])

  const handleClose = () => {
    setOpen(false);
    refresh();
  };

  const sendNotification = async (message) => {
    return await axiosInstance.post('/notifications', {
      customerID: userToSend.customerID,
      messageID: message.ID,
      token: userToSend.deviceToken,
      requestID: userToSend.requestID,
      queueID: 1
    })
    .then(res => {
      console.log(res.data)
      toast.success('Successfully sent notification')
        
    })
    .catch(err => {
      toast.error(err.response.data.message)
    })
  }


  return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Messages</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select message 
          </DialogContentText>

          <List
            component="nav"
            aria-labelledby="nested-list-subheader" 
          >
            { messages ?
               messages.map(message => {
                return (
                <ListItem button key={message.ID} onClick={() => {sendNotification(message); handleClose();}}>
                  <ListItemIcon>
                      <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary={message.Title} secondary={message.Body}/>
                </ListItem>)
               })
          : null}
          </List>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
  );
}