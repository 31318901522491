import React, { useState, useEffect } from "react";
import socketIOClient from "socket.io-client";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import axiosInstance from "../config/axiosConfig";
import MessageDialog from './MessageDialog';
import QueueTable from '../components/QueueTable';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import Badge from '@material-ui/core/Badge';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import HistoryIcon from '@material-ui/icons/History';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import AddUserDialog from './ManualEnqueueDialog/AddUserDialog';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Divider from '@material-ui/core/Divider'
import NotificationsIcon from '@material-ui/icons/Notifications';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import UserMenu from './UserMenu';
import MenuPopper from './MenuPopper';
import { Tooltip } from "@material-ui/core";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../static/styles/toastStyles.css';
import NotificationsPopper from "./NotificationsPopper";
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Clock from "./Common/Clock";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -5,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

const useStyles =  makeStyles((theme) => ({

  root: {
    flexGrow:1,
    backgroundColor: theme.palette.background.root
  },


  queue: {
    overflow: 'auto',
    // backgroundColor: 'blue'
  },

  queueItem: {
    width: '60%',
    height: '10%',
   },

  buttonsGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-evenly',
    width: "40vw",
    marginBottom: 15
  },

  button:{
    // backgroundColor: '#f5f3f4',
    margin:15
  }
}))

function QueueControl({lightTheme, setLightTheme}) {
  // console.log(setLightTheme)
  const classes = useStyles();
  const [queue, setQueue] = useState([]);
  const [range, setRange] = useState(0);
  const [messages, setMessages] = useState([]);
  const [openMessage, setOpenMessage] = useState(false);
  const [usersToAccept, setUsersToAccept] = useState([1]);
  const [userToSend, setUserToSend] = useState(null);
  const [history, setHistory ] = useState(null);
  const [openUser, setOpenUser] = useState(false);
  const [openNavMenu, setOpenNavMenu] = useState(false);
  const [openUserList, setOpenUserList] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsLen, setNotificationsLen] = useState(0);
  const [connection, setConnection] = useState(null)

  const notificationsAnchorRef = React.useRef(null);

  const ulAnchorRef = React.useRef(null);
  /* For popper navbar menu */
  const navAnchorRef = React.useRef(null);

  /* For popper navbar menu */
  const handleToggle = (setter) => {
    setter((prevOpen) => !prevOpen);
  };

  const getQueue = async (rangeID) => {
    setRange(rangeID);
    await axiosInstance.get('/getqueue', { params: { rangeID:rangeID, queueID: 1}})
    .then(res => {
      console.log(res)
      if(res.data === ''){
        // toast('Η ουρά είναι άδεια');
        setQueue([])
      }
      else
        setQueue(res.data) 
    })//setQueue(res.data.queue)})
    .catch(err => console.log(err))
  }

  const getMessages = async () => {
    await axiosInstance.get('/getmessages', {params: { storeID: 1}})
    .then(res => setMessages(res.data))//setMessages(res.data))
    .catch(err => console.log(err))
  }

  const dequeue = async (requestID, status) => {
    const confirmation = window.confirm("Ο πελάτης θα ενημερωθεί για την αποχώρησή του απο την ουρά, είστε σίγουρος/η;");
    if(confirmation){
      return await axiosInstance.post('/dequeue', {
        requestID: requestID,
        status: status,
        rangeID: range,
        queueID: 1
      })
      .then(res => {
        console.log(res)
        getQueue(range)
      })
      .catch(err => {
        toast.error(`${err.response.data.message}`);
      })
    }
  }

  const handleAcceptUser = async (user) => {
    return await axiosInstance.post('/enqueueAccept', user)
    .then(res => {
      console.log(res);
      getQueue(range)
    })
    .catch(err => {
      console.log(err)
    })
  }

  const getHistory = async (history, range) => {
    await axiosInstance.get('/gethistory', { params: { historyType: history, rangeID: range, queueID: 1}})
    .then(res => {
      if(res.data === ''){
        // toast('Η ουρά είναι άδεια');
        setQueue([])
      }
      else
        setQueue(res.data)
    })
    .catch(err => console.log(err))
  }

  const refresh = () => {
    if(history)
      getHistory(history,range)
    else
      getQueue(range);
  }

  useEffect( () => {
    
    getQueue(0);
    
    getMessages();

    const newConnection = new HubConnectionBuilder()
            .withUrl('https://qmsrv.qubiteq.gr/subscribe')
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);

  /*  const socket = socketIOClient(process.env.REACT_APP_SERVER_DOMAIN);

    socket.on("enqueue-accept", data => {
      refresh();
      toast.info(`${data.FirstName} just got queued`)
    });
    
    socket.on("enqueue-request", data => {
      setUsersToAccept(prev => [...prev, data]);
      toast.info('User requested to join')
    })

    socket.on('message-response', data => {
      console.log(data)
      refresh();

      const prev = notifications
      prev.unshift(data)
      console.log(prev)
      console.log('notifications before:',prev)
      setNotifications(prev)

      toast.info(`${data.FirstName} ${data.CellPhone} ${data.Answer}`)
    })

    socket.on("dequeue", data => {
      console.log(data)
      refresh();
      data.Status === 'QCancelled' ? toast.error(`${data.FirstName} just got dequeued`) : toast.success(`${data.FirstName} is now seated`)
    })

    socket.on('refresh', () => {
      toast('User responded')
    })*/

    const savedNotifications = JSON.parse(localStorage.getItem('notifications'));

    if(notifications.length === 0 && savedNotifications && savedNotifications.length > 0){
      setNotifications(savedNotifications)
    }

    window.onbeforeunload = () => {
      if(notifications.length > 0)
        localStorage.setItem('notifications', JSON.stringify(notifications))
    }

    return () => {
      setMessages([]);
      setQueue([]);
      // socket.disconnect();
      localStorage.removeItem('notifications')
    }
  }, []);

  useEffect(() => {
    if (connection) {
        connection.start()
            .then(result => {
                console.log('Connected!');

                connection.on("EnqueueAccept", stringData => {
                  const data = JSON.parse(stringData)[0];
                  if(data.Customer !== null)
                  {
                    refresh();
                    toast.info(`${data.FirstName} just got queued`)
                  }
                });

                connection.on("MessageResponse", stringData => {
                  const data = JSON.parse(stringData)[0];
                  refresh();
                  toast.info(`${data.FirstName} ${data.CellPhone} ${data.Answer}`)
                })

                connection.on("Dequeue", stringData => {
                  const data = JSON.parse(stringData)[0];
                  refresh();
                  data.Status === 'QCancelled' ? toast.error(`${data.FirstName} just got dequeued`) : toast.success(`${data.FirstName} is now seated`)
                })

                connection.on("ReEnqueue", stringData => {
                  const data = JSON.parse(stringData)[0];
                  refresh();
                  toast.success(`${data.FirstName} just got re-enqueued`)
                })
            })
            .catch(e => console.log('Connection failed: ', e));
    }
}, [connection]);

  useEffect( () => {
    refresh()
  }, [range, history])
  

  return (
    <React.Fragment>
    <AddUserDialog open={openUser} setOpen={setOpenUser} refresh={refresh}/>
    <MessageDialog messages={messages} open={openMessage} setOpen={setOpenMessage} userToSend={userToSend} refresh={refresh}/>
    {/* <AcceptUserDialog handleAcceptUser={handleAcceptUser} open={openRequest} setOpen={setOpenRequest}/> */}

      <Grid container justify="center" direction={"column"} alignItems={"center"} className={classes.root}>

        <Grid container item xs={10} direction={'row'} justify={'center'} alignItems={'center'}>
          <Typography color="textPrimary" variant="h5">
            Queueme
          </Typography>

          <div style={{flex: '1 1 auto'}} />

          {/* <IconButton
            ref={ulAnchorRef}
            aria-controls={openUserList ? 'user-list-grow-' : undefined}
            aria-haspopup="true"
            // onClick={() => handleToggle(setOpenUserList)}
          >
            <StyledBadge color="secondary" style={{color: '#363B39'}} badgeContent={0}>
              <Tooltip title='Remote requests (Not yet available)'>
                <PersonAddIcon />
              </Tooltip>
            </StyledBadge>

          </IconButton> */}
          <Clock />
          <MenuPopper
              open={openUserList}
              setOpen={setOpenUserList}
              anchorRef={ulAnchorRef}
              // list={testList}
            />

            <IconButton
              ref={notificationsAnchorRef}
              aria-controls={openNotifications ? 'notifications-grow' : undefined}
              aria-haspopup="true"
              onClick={() => handleToggle(setOpenNotifications)}
            >
              <StyledBadge color="secondary" badgeContent={notifications.length}>
                <Tooltip title='Messages and Notifications'>
                  <NotificationsIcon />
                </Tooltip>
              </StyledBadge>
            </IconButton>
            <NotificationsPopper
              open={openNotifications}
              setOpen={setOpenNotifications}
              anchorRef={notificationsAnchorRef}
              list={notifications}
            />

          
            <IconButton
              ref={navAnchorRef}
              aria-controls={openNavMenu ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={() => handleToggle(setOpenNavMenu)}
            >
              <Tooltip title='User Menu'>
                <MenuIcon />
              </Tooltip>
            </IconButton>
            <UserMenu
                open={openNavMenu}
                setOpen={setOpenNavMenu}
                anchorRef={navAnchorRef}
              />

            <IconButton
              ref={navAnchorRef}
              onClick={() => {localStorage.setItem('theme', lightTheme ? 'dark': 'light'); setLightTheme(prev => !prev) }}
            >
              <Tooltip title="Change theme">
                <Brightness4Icon />
              </Tooltip>
            </IconButton>

          <Divider style={{ width: '100%', marginBottom:15}}/>
        </Grid>

        <Grid item container xs={12} direction={"row"} justify={"center"} alignItems={"center"}>
        
          <ToggleButtonGroup orientation="horizontal" value={range} exclusive onChange={(event,nextRange)=>{setRange(nextRange)}}>
            <ToggleButton value={0} aria-label="list" >
              <Typography variant='button' color='textPrimary'>
                All
              </Typography>
            </ToggleButton>
            <ToggleButton value={1} aria-label="module" style={{width: 50}}>
              <Typography variant='button' color='textPrimary'>
                1-4
              </Typography>
            </ToggleButton>
            <ToggleButton value={2} aria-label="quilt" style={{width: 50}}>
              <Typography variant='button' color='textPrimary'>
                5-6
              </Typography>
            </ToggleButton>
            <ToggleButton value={3} aria-label="quilt" style={{width: 50}}>
              <Typography variant='button' color='textPrimary'>
                6+
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup orientation="horizontal" value={history} exclusive onChange={(event,nextHistory)=>{setHistory(nextHistory)}} style={{marginLeft: 20}}>
            <ToggleButton value={2} aria-label="rejected" >
              <HistoryIcon/>
            </ToggleButton>
            <ToggleButton value={3} aria-label="resolved" >
              <RestaurantMenuIcon/>
            </ToggleButton>
          </ToggleButtonGroup>
            
        </Grid>

        <Grid item xs={12} className={classes.queue} >
          <QueueTable 
            queue={queue} 
            dequeue={dequeue}
            setOpen={setOpenUser} 
            setOpenMessage={setOpenMessage}
            setUserToSend={setUserToSend}
            history={history}
            refresh={refresh}
            range={range}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default QueueControl;