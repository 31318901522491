import { Typography } from '@material-ui/core';
import React from 'react'

var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const Clock = props => {
    const [date, setDate] = React.useState(new Date());
  
   React.useEffect(() => {
    var timerID = setInterval( () => tick(), 1000 );
    return function cleanup() {
        clearInterval(timerID);
      };
   });
  
     function tick() {
      setDate(new Date());
     }
  
     return (
        <Typography color="textPrimary" variant='body1'>
          {`${days[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]} ${date.getHours() < 10 ? 0 : ''}${date.getHours()}:${date.getMinutes() < 10 ? 0 : ''}${date.getMinutes()}`}
        </Typography>
      );
  }
  
export default Clock;
  