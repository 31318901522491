/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { Typography, List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core";

import styles from "../static/styles/footerStyle";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const theme = useTheme();
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={classes.footer} style={{backgroundColor: theme.palette.background.root}}>
      <div className={classes.footContainer}>
        <List className={classes.container}>
          <ListItem className={classes.inlineBlock}>
            <a
              className={classes.block}
              target="_blank"
              href="https://qubiteq.gr/company/"
            >
              <Typography color="textPrimary">
                About Us
              </Typography>
            </a>
          </ListItem>

          <ListItem className={classes.spacer}/>

          <ListItem className={classes.inlineBlock}>
            <a target="_blank" rel="noreferrer"  href="https://qubiteq.gr/" className={classes.block}>
              <Typography color="textPrimary">
                Qubiteq &copy; {1900 + new Date().getYear()}
              </Typography>
            </a>
          </ListItem>

        </List>
      </div>
    </footer>
  );
}