import React, { useState, useEffect } from 'react';
import axiosInstance from '../config/axiosConfig';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Timeline from '@material-ui/lab/Timeline';

import QueueIcon from '@material-ui/icons/Queue';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import RedoIcon from '@material-ui/icons/Redo';
import EventItem from './EventItem';

export default function EventsDialog({open, setOpen, events}) {

  const getIconByAction = (actionID) => {
    switch(actionID){
        case 1:
            return <QueueIcon/>
        case 2:
            return <QuestionAnswerIcon />
        case 3:
        case 4:
            return <CancelIcon/>
        case 5:
            return <DoneAllIcon />
        case 6:
          return <RedoIcon />
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm" >
        <DialogTitle id="form-dialog-title">Events</DialogTitle>
        <DialogContent>
          <DialogContentText>
            See events
          </DialogContentText>
          <Timeline align="alternate">
            {events ?
               events.map((event,row) => {
                return (
                  <React.Fragment key={row}>
                    {event.ResponseTimestamp ? 
                      <EventItem 
                        key={event.ID + 'Response'}
                        eventID={event.ID + 'Response'} 
                        eventTimestamp={event.ResponseTimestamp}
                        eventColor={event.ResponseColor}
                        eventActionID={event.ResponseID}
                        eventsLength={events.length}
                        row={row}
                        eventDescription={event.ResponseDescription}
                        getIconByAction={getIconByAction}
                      /> 
                    : null}
                    <EventItem 
                      key={event.ID}
                      eventID={event.ID} 
                      eventTimestamp={event.Timestamp}
                      eventColor={event.Color}
                      eventActionID={event.ActionID}
                      eventsLength={events.length}
                      row={row}
                      eventDescription={event.Description}
                      eventBody={event.Body}
                      getIconByAction={getIconByAction}
                    /> 
                  </React.Fragment>
                )}) : null}
          </Timeline>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
  );
}