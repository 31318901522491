import React from "react";
import { lighten, makeStyles } from '@material-ui/core/styles';
import { IconButton, Toolbar, Tooltip, Typography, Grid } from "@material-ui/core";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplayIcon from '@material-ui/icons/Replay';


const useToolbarStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: 'transparent',
      width:'100%',
      zIndex: 1
    },
    title: {
      flex: '1 1 100%'
    },
  }));
  
  const QueueTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { handleEnqueue, handleClearQueue, undoClearQueue, history, empty } = props;
  
    return (
      <Grid container>
        <Toolbar
          className={classes.root}>

          {/* <Typography variant="h6" id="tableTitle" >
              Queue
          </Typography> */}

          <div style={{flex:1}}/>
          { !history && !empty && <Tooltip title="Clear queue">
          <IconButton aria-label="clearQueue" onClick={handleClearQueue}>
              <DeleteIcon  />
          </IconButton>
          </Tooltip>}

          { empty && <Tooltip title="Undo clear queue">
          <IconButton aria-label="undoClearQueue" onClick={undoClearQueue}>
              <ReplayIcon  />
          </IconButton>
          </Tooltip>}

          <Tooltip title="Enqueue">
          <IconButton aria-label="enqueue" onClick={handleEnqueue}>
              <PersonAddIcon  />
          </IconButton>
          </Tooltip>
        </Toolbar>
      </Grid>
    );
  };

export default QueueTableToolbar;
  