import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    textField : {
        margin:2
    }
}))

const CustomerForm = ({error, people, helperText, setPeople, comments, setComments, handleChange, vaccination, setVaccination}) => {
    const classes = useStyles();

    return (
        <Grid item container xs={12} direction="row" justify="center">
            <TextField
              error={error}
              autoFocus
              required
              margin="dense"
              id="people"
              label="People"
              value={people}
              helperText={helperText}
              onChange={(e) => handleChange(e,setPeople)}
              className={classes.textField}
            />
            <TextField
              error={error}
              
              required
              margin="dense"
              id="comments"
              label="Comments"
              value={comments}
              onChange={(e) => handleChange(e,setComments)}
              className={classes.textField}
            />
            <FormControlLabel control={
                <Checkbox
                    color='primary'
                    checked={vaccination===true}
                    value={vaccination}
                    onChange={(e) => {console.log(e.target.value); setVaccination(prev => !prev)}}
                />}
                label='Vaccination'
            />
        </Grid>
    )
    
}

export default CustomerForm;