import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import TimerIcon from '@material-ui/icons/Timer';
import PeopleIcon from '@material-ui/icons/People';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import EmailIcon from '@material-ui/icons/Email';
import Tooltip from '@material-ui/core/Tooltip';
import QueueTableToolbar from './QueueTableToolbar';
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HelpIcon from '@material-ui/icons/Help';
import EditIcon from '@material-ui/icons/Edit';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import SaveIcon from '@material-ui/icons/Save';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EventsDialog from './EventsDialog';
import axiosInstance from '../config/axiosConfig';
import UndoIcon from '@material-ui/icons/Undo';
import ClearIcon from '@material-ui/icons/Clear';
import { toast } from 'react-toastify';
import { TextField } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    container: {
        overflowX: 'auto',
        overflowY:'auto',
        height: 0.7*window.innerHeight,
        minWidth: '60vw'
        // backgroundColor: '#212227'
    },

    table: {
      border: '1px transparent',
    //   backgroundColor: '#212227',
    },

    userGrid: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },

    flexRowGrid: {
        display: 'flex',
        flexDirection: 'row', 
        justifyContent:'center'
    },

    flexColumnGrid: {
        display: 'flex',
        flexDirection: 'column', 
        justifyContent:'center'
    },

    hcells: {
        // backgroundColor: '#f5f3f4',
        border: '1px transparent',
        alignItems: 'flex-start'
    },

  }));

const QueueTable = ({queue, setUserToSend, setOpenMessage, dequeue, setOpen, history, range, refresh}) => {
    const theme = useTheme();
    const classes = useStyles();
    const [openEvents, setOpenEvents] = useState(false);
    const [events, setEvents] = useState(null);
    const [edit, setEdit] = useState({});
    const [people, setPeople] = useState(null)
    const [vaccinated, setVaccinated] = useState(null)

    console.log(process.env.REACT_APP_QMS_DOMAIN)
    console.log('Process env', process.env)

    const getBadge = (key, color, rowColor, message) => {
        const style = rowColor ? {color: theme.palette.text.primary} : {color:color};
        // console.log(key)
        switch(key){
          case 'Active': 
            return <HourglassEmptyIcon style={style} />
      
          case 'Pending':
            return <Tooltip title={message}>
                        <CachedRoundedIcon style={style} />
                    </Tooltip>
      
          case 'UCancelled':
          case 'QCancelled':
            return <ErrorOutlineIcon style={style}/>
          
          case 'Resolved':
            return <DoneAllIcon style={style} />
          
          case 'Incoming': 
            return < DoneIcon/>
          default:
            return <HelpIcon style={style} />
        }
      }

    const handleSendMessages = (customerObject) => {
        setUserToSend(customerObject);
        setOpenMessage(true)
    }

    const displayEvents = (requestID) => {
        axiosInstance.get('/getevents', { params: {
            requestID: requestID
        }})
          .then(res => {
            setEvents(res.data);
            setOpenEvents(true);
          })
          .catch(err => {
            console.log(err)
          })
    }

    const givePriority = (requestID) => {
        axiosInstance.post('/givepriority', {
            requestID: requestID,
            rangeID: range
        })
          .then(res => {
            refresh();
          })
          .catch(err => {
            console.log(err)
          })
    }

    const takePriority = requestID => {
        axiosInstance.post('/takepriority', {
            requestID: requestID,
            rangeID: range
        })
          .then(res => {
            refresh();
          })
          .catch(err => {
            console.log(err)
          })
    }

    const reEnqueue = (requestID, firstName) => {
        console.log(process.env.REACT_APP_QMS_DOMAIN)
        axiosInstance.post('/reenqueue', {
            requestID: requestID,
            queueID: 1
        })
          .then(res => {
            const response = res.data[0]
            response.Status === 'Success' ? toast.success(`${firstName} ${response.Result}`) : toast.error(`${firstName} ${response.Result}`)
            refresh();
          })
          .catch(err => {
            if(err.response)
                err.response.status === 403 ? toast.error(`${err.response.data.message}`) : toast.error("Something went wrong");
            refresh();
          })
    }

    const handleClearQueue = () => {
        const confirmation = window.confirm("Η ουρά θα αδειάσει χωρίς οι χρήστες να λάβουν ειδοποίηση, είστε σίγουρος/η;");
        if(confirmation){
            axiosInstance.get('/clearqueue', {
                parameters: {
                    queueID: 1
                }
            })
            .then(res => {
                refresh();
            })
            .catch(error => {
                toast.error(error.response.data.message);
            })
        }
        
    }

    const undoClearQueue = () => {
        const confirmation = window.confirm("Ο καθαρισμός της ουράς θα αναιρεθεί, είστε σίγουρος/η;");
        if(confirmation){
            axiosInstance.get('/undoclearqueue', {
                parameters: {
                    queueID: 1
                }
            })
            .then(res => {
                refresh();
            })
            .catch(error => {
                toast.error(error.response.data.message);
            })
        }
        
    }

    const editPeople = (item) => {
        axiosInstance.post('/editpeople', {
            requestID: item.ID,
            queueID:1,
            people: people || item.People,
            vaccinated: vaccinated
        })
        .then(res => {
            setEdit(prev => ({...prev, [item.ID]:false}))
            const response = res.data[0]
            response.Status === 'Success' ? toast.success(`Successfully updated`) : toast.error(`${response.Result}`)
            refresh()
        })
        .catch(err => {
            if(err.response)
                err.response.status === 403 ? toast.error(`${err.response.data.message}`) : toast.error("Something went wrong");
            refresh();
        })
    }

    return  (
    <Grid container>
        <EventsDialog open={openEvents} setOpen={setOpenEvents} events={events}/>
    <QueueTableToolbar handleEnqueue={() => setOpen(true)} handleClearQueue={handleClearQueue} undoClearQueue={undoClearQueue} history={history} empty={queue?.length === 0}/>
    <TableContainer component={Paper} className={classes.container}>
        <Table stickyHeader size='small' className={classes.table} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell className={classes.hcells} align="center">
                    <Typography variant="subtitle1">
                        #
                    </Typography>
                </TableCell>
                <TableCell className={classes.hcells} align="center">
                    <Tooltip title="First Name">
                        <AccountCircleIcon/>
                    </Tooltip>
                </TableCell>
                <TableCell className={classes.hcells} align="center">
                    <Tooltip title="Cell phone">
                        <LocalPhoneIcon />
                    </Tooltip>
                </TableCell>
                <TableCell className={classes.hcells} align="center">
                    <Typography variant="subtitle1">
                        Σχόλια
                    </Typography>
                </TableCell>
                <TableCell className={classes.hcells} align="center">
                    <Tooltip title="Time of request">
                        <AccessTimeIcon />
                    </Tooltip>
                </TableCell>
                <TableCell className={classes.hcells} align="center">
                    <Tooltip title="Elapsed time">
                        <TimerIcon />
                    </Tooltip>
                </TableCell>
                <TableCell className={classes.hcells} align="center">
                    <Tooltip title="People">
                        <PeopleIcon />
                    </Tooltip>
                </TableCell>
                <TableCell className={classes.hcells} align="center">
                    <Typography variant="subtitle1">
                        Κατάσταση
                    </Typography>
                </TableCell>
                <TableCell className={classes.hcells} align="center">
                    <Typography variant="subtitle1">
                        Εμβολιασμός
                    </Typography>
                </TableCell>
                <TableCell className={classes.hcells} align="center">
                    <Typography variant="subtitle1">
                        Ενέργειες
                    </Typography>
                </TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {queue.map((item) => {
                return <TableRow hover key={item.Position} onDoubleClick={() => displayEvents(item.ID)} style={{backgroundColor:(item.RowColor ? item.RowColor : null)}}>
                    
                    <TableCell align="left" className={classes.cells}>
                        <Grid className={classes.userGrid}>
                        <Typography variant="body1">
                            {item.Position}
                        </Typography>
                        
                        </Grid>
                    </TableCell>

                    <TableCell align="left" className={classes.cells}>
                        <Grid className={classes.userGrid}>
                        <Typography variant="body1">
                            {item.FirstName}
                        </Typography>
                        
                        </Grid>
                    </TableCell>

                    <TableCell align="left" className={classes.cells}>
                            <Typography variant="subtitle1" color="textSecondary" >
                                {item.CellPhone}
                            </Typography>

                    </TableCell>

                    <TableCell align="left" className={classes.cells}>
                            <Typography variant="subtitle1" color="textSecondary" >
                                {item.Comments}
                            </Typography>

                    </TableCell>

                    <TableCell align="left" className={classes.cells}>
                        <Grid className={classes.flexRowGrid}>
                            <Typography variant="subtitle1" color="textSecondary" style={{paddingRight: 5}}>
                                {item.DateCreated}
                            </Typography>
                        </Grid>
                    </TableCell>

                    <TableCell align="left" className={classes.cells}>
                        <Grid className={classes.flexRowGrid} >
                            <Typography variant="subtitle1" color="textSecondary" style={{paddingRight: 5}}>
                               { `${item.ElapsedTime}'`}
                            </Typography>
                            
                        </Grid>
                    </TableCell>

                    <TableCell align="left" className={classes.cells} onDoubleClick={e => { if(edit[item.ID]) e.stopPropagation()}}>
                        <Grid className={classes.flexRowGrid} >
                        {edit[item.ID] ?
                            <TextField
                                variant='outlined'
                                size='small'
                                defaultValue={item.People}
                                type='number'
                                onBlur={(e) => setPeople(e.target.value)}
                                style={{width: 65}}
                            />
                        :
                            <Typography variant="h6" color="textPrimary" style={{paddingRight: 5}}>
                                {item.People}
                            </Typography>}
                        </Grid>
                    </TableCell>

                    <TableCell align="center" className={classes.cells}>
                        {getBadge(item.Status, item.Color, item.RowColor, `${item.TimeSinceFirstNotification}' passed since first notified`)}
                    </TableCell>

                    <TableCell align="center" className={classes.cells}>
                    {edit[item.ID] ?
                        <Checkbox 
                            checked={vaccinated} 
                            color='primary'
                            value={vaccinated}
                            onChange={(e) => {e.stopPropagation(); e.preventDefault(); setVaccinated(prev => !prev)}}
                        />
                        :
                        <Typography variant="h6" color="textPrimary" style={{paddingRight: 5}}>
                            {item?.Vaccinated === null ? '' : ((item?.Vaccinated === true) ? 'Ναι' : 'Οχι') }
                        </Typography>
                    }   
                    </TableCell>

                    <TableCell align="left" className={classes.cells}>
                        <Grid container justify={'center'}>
                            <Grid item container xs={12} wrap='nowrap' >
                            { history ? 
                                <Tooltip title="Re-enqueue user">
                                <IconButton onClick={() => {reEnqueue(item.ID, item.FirstName)}} onDoubleClick={(e) => e.stopPropagation()}>
                                    <UndoIcon />
                                </IconButton>
                                </Tooltip> :
                                <React.Fragment>
                                    {!edit[item.ID] && <>
                                    <Tooltip title="Give priority">
                                    <IconButton onClick={() => takePriority(item.ID)} onDoubleClick={(e) => e.stopPropagation()}>
                                        <ExpandLessIcon />
                                    </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Take priority">
                                    <IconButton onClick={() => givePriority(item.ID)} onDoubleClick={(e) => e.stopPropagation()}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Send notification">
                                    <IconButton 
                                        onClick={() => handleSendMessages({customerID: item.CustomerID, deviceToken: item.DeviceToken, requestID: item.ID})} 
                                        onDoubleClick={(e) => e.stopPropagation()}
                                    >    
                                        <EmailIcon/>
                                    </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Eat">
                                    <IconButton onClick={() => {dequeue(item.ID, 'Resolved')}} onDoubleClick={(e) => e.stopPropagation()}>
                                        <RestaurantIcon />
                                    </IconButton>
                                    </Tooltip>
                                    </>}
                                    
                                    {edit[item.ID] ? <Tooltip title="Save change">
                                    <IconButton onClick={() => {editPeople(item)}} onDoubleClick={(e) => e.stopPropagation()}>
                                        <SaveIcon/>
                                    </IconButton>
                                    </Tooltip> : 
                                    <Tooltip title="Edit people">
                                    <IconButton onClick={() => {console.log('setEdit', item.ID); setVaccinated(item?.Vaccinated); setEdit(prev => ({...prev, [item.ID]: true}))}} onDoubleClick={e => e.stopPropagation()}>
                                        <EditIcon />
                                    </IconButton>
                                    </Tooltip>}
                                    
                                    {edit[item.ID] ? <Tooltip title="Discard changes">
                                    <IconButton onClick={() => {console.log('cancelEdit', item.ID); setEdit(prev => ({...prev, [item.ID]: false}))}} onDoubleClick={e => e.stopPropagation()}>
                                        <ClearIcon />
                                    </IconButton>
                                    </Tooltip>
                                    :
                                    <Tooltip title="Delete from queue">
                                    <IconButton onClick={() => {dequeue(item.ID, 'QCancelled')}} onDoubleClick={(e) => e.stopPropagation()}>
                                        <DeleteIcon/>
                                    </IconButton>
                                    </Tooltip>}
                                </React.Fragment>}
                            </Grid>
                        </Grid>
                    </TableCell>


                </TableRow>
})}
            </TableBody>
        </Table>
        </TableContainer>
        </Grid>)

}


export default QueueTable;