import React, { useEffect } from 'react';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
      },

}));

export default function EventItem({eventID, eventTimestamp, eventsLength, eventColor, eventActionID, eventDescription, eventBody, row, getIconByAction}){
    const classes = useStyles();

    return (
        <TimelineItem key={eventID}>
            <TimelineOppositeContent>
            <Typography variant="h6" color="textPrimary" noWrap>
                {eventTimestamp}
            </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
            <TimelineDot style={{backgroundColor: eventColor}}>
                {getIconByAction(eventActionID)}
            </TimelineDot>
            {row !== eventsLength-1 ?
                <TimelineConnector />
            : null}
            </TimelineSeparator>
            <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1" >
                    {eventDescription}
                </Typography>
                <Typography >
                    {eventBody}
                </Typography>
            </Paper>
            </TimelineContent>
        </TimelineItem> )}