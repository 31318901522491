import './App.css';
import React from 'react';
import Router from './components/Router';
import darkTheme from './themes/darkTheme';
import lightTheme from './themes/lightTheme';
import Footer from './components/Footer';
import Grid from '@material-ui/core/Grid';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

function App() {
  const [isLightTheme, setIsLightTheme] = React.useState(localStorage.getItem("theme") === 'light' ? true : false)
  return ( 
    <ThemeProvider theme={isLightTheme ? lightTheme : darkTheme}>
      <Grid container direction='column' style={{minHeight:'100vh'}}>
        <Router lightTheme={isLightTheme} setLightTheme={setIsLightTheme}/>
        <Footer />
      </Grid>
    </ThemeProvider>
  );
}

export default App;
