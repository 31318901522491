import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
    zIndex: 10
  },
}));

export default function MenuPopper({open, setOpen, anchorRef, list}) {
  const classes = useStyles();

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
        // <Button
        //   ref={anchorRef}
        //   aria-controls={open ? 'menu-list-grow' : undefined}
        //   aria-haspopup="true"
        //   onClick={handleToggle}
        // >
        //   Toggle Menu Grow
        // </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 10}}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {list.map(item => (
                        <MenuItem onClick={handleClose} key={item.ID}>
                            <Grid container justify='flex-start' direction='row' wrap='nowrap' alignItems='center'>
                                <Grid item container xs={6} direction={'row'} justify='flex-start' alignItems={'baseline'} wrap="nowrap">
                                    <Typography variant='body2'>
                                        {item.FirstName}
                                    </Typography>
                                    <Typography variant='subtitle1' color='textSecondary' style={{marginLeft:5}}>
                                        {item.CellPhone}
                                    </Typography>
                                </Grid>
                                <div style={{flex: '1 1 auto'}} />
                                <Grid item container xs={5} direction='row'>
                                    <IconButton edge='end' style={{color: '#4caf50'}}>
                                        <CheckIcon />
                                    </IconButton>
                                    <IconButton style={{color: '#f44336'}}>
                                        <ClearIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            
                        </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
  );
}