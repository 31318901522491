import React, { useState } from "react";
import auth from "../../Services/authService";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect, useHistory } from "react-router";
import LinearProgress from '@material-ui/core/LinearProgress';
import { toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <a target="_blank" rel="noreferrer" href="https://qubiteq.gr/" style={{textDecoration:'none'}}>
        Qubiteq
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '85vh',
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    //backgroundSize: "cover",
    backgroundPosition: "center",
  },
  logo: {
    display: 'flex',    
    margin: "auto",
    flexDirection: "column",
    alignItems: 'center', 
  },

  gridItemPaper: {
    width: '30vw',
    minWidth:320,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      width: '100vw'
    }
  },

  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#EE3434',
  },
  form: {
    marginTop: theme.spacing(1),

  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LoginForm(props) {
    const [data, setData] = useState({
      userName: "",
      password: "",
      validateΕrr: "",
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const history = useHistory();
  
    const classes = useStyles();
  
    var handleSubmit = (e) => {
      setLoading(true);
      e.preventDefault();
      // const errors = this.validate();
      // this.setState({ errors: errors || {} });
      // if (errors) return;
  
      doSubmit();
    };
  
    var handleChange = ({ target: input }) => {
      const newData = { ...data };
      newData[input.name] = input.value;
      setData(newData);
    };
  
    var doSubmit = async () => {
      console.log('heher')
      auth.login(data.userName, data.password)
      .then(res => {
        console.log(res)
        if (!res) {
          let newData = { ...data };
          newData.validateΕrr = 'Failed to login';
          setData(newData);
          setError(true)
          setLoading(false)
        } else {
          history.push('/controlPanel')
          // const { state } = props.location;
          // window.location = state ? state.from.pathname : "/ControlPanel";
        }
      })
      .catch(err => {
        let newData = { ...data };
        newData.validateΕrr = err;
        setData(newData);
        setError(true)
        setLoading(false)
      })
    };
  
    return (
      auth.getCurrentUser() ? <Redirect to={{pathname: '/ControlPanel'}}></Redirect> :
      <Grid container component="main" spacing={0}
      direction="column"
      alignItems="center"
      justify="center" className={classes.root}>
        <CssBaseline />
        <Grid item component={Paper} elevation={3} xs={4} className={classes.gridItemPaper}>
          {loading ? 
            <LinearProgress /> : null }
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form onSubmit={handleSubmit} className={classes.form}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="userName"
                autoComplete="email"
                autoFocus
                helperText={data.validateΕrr}
                value={data.userName}
                onChange={(e) => handleChange(e)}
                error={error}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={data.password}
                onChange={(e) => handleChange(e)}
                error={error}
                //autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="outlined"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Box mt={2}>
                <Copyright />
              </Box>
              <Box mt={2} className={classes.logo}>
                <img src="/qubiteqlogo.png" alt="" ></img>
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  }
  