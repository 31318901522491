import jwtDecode from "jwt-decode";
import http from "./httpService";
import axiosConfigAuth from '../config/axiosConfigAuth'

const tokenKey = "token";
const userKey = "userId";
const sessionKey = "sessionGuid";

http.setToken(getJwt());
http.setUserId(getUserId());
http.setSessionGuid(getSessionGuid());


export async function login(username, password) {
  return await axiosConfigAuth.post('/Login', {username: username, password: password})
  .then(res => {
    if(res.data.Result){
      throw res.data.Result
    }
    else if( res.data.token){
      http.setToken(res.data.token);
      loginWithJwt(res.data.token);
    }
    return res
  })
  .catch(err => {throw err})
  // if(username==='sardelaki' && password==='sardelakidemo123!'){
  //   const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2MjM0MzM4MzgsImV4cCI6MTYyMzQ0MTAzOCwiaWF0IjoxNjIzNDMzODM4fQ.QsJ0davLYIfA72l9xCEqqu7wkkzNFwNgd3cgawyxDSs";
    // http.setToken(token);
    // loginWithJwt(token);
    // return token
  // }
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(userKey);
  localStorage.removeItem(sessionKey);
  localStorage.removeItem('tabsOpen');
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
}

export function getUserId() {
  return localStorage.getItem(userKey) || sessionStorage.getItem(userKey);
}

export function getSessionGuid() {
  return localStorage.getItem(sessionKey) || sessionStorage.getItem(sessionKey);
}

const auth = {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt
  //getUserId,
  //getSessionGuid,
};
export default auth;